export const appBaseURL = "/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=0"},{"charset":"utf-8"},{"name":"keywords","content":"郑州路桥建设投资集团有限公司"},{"name":"description","content":"郑州路桥建设投资集团有限公司是郑州市政府批准设立的国有独资公司，成立于2003年11月，注册资本4.557亿元，资产总规模119亿元，逐步形成集合战略投资、规划设计、基础建设、施工监理、新型材料、智慧交通、城市运营、资产管理、人文康养等多业态跨界与产业链整合的综合性集团公司。"}],"link":[{"rel":"shortcut icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"//api.tianditu.gov.cn/api?v=4.0&tk=b6f3992f123877c6f0f7d1d84912f548"}],"noscript":[],"title":"郑州路桥建设投资集团有限公司","htmlAttrs":{"lang":"zh-hans"}}

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"