import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47components_47publicPage_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/components/publicPage.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djdt_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/djdt.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djll_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/djll.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47dyfc_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/dyfc.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47index_46jsxMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/index.jsx?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47ljjs_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/ljjs.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47qnzs_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/qnzs.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xq_47_91partyBuildingId_93_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/xq/[partyBuildingId].vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xxgcddesdjs_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/xxgcddesdjs.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47zgzj_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/dqjs/zgzj.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47components_47publicPage_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/gywm/components/publicPage.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_46jsxMeta } from "E:/develop/集团官网/zzrb-gw/pages/gywm/index.jsx?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_copy_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/gywm/index_copy.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47jtjj_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/gywm/jtjj.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47_91id_93_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/gywm/lqqx/[id].vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47index_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/gywm/lqqx/index.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47qyry_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/gywm/qyry.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47zzjg_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/gywm/zzjg.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47index_47index_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/index/index.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47qywh_47index_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/qywh/index.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47list_page_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/components/list_page.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47rich_text_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/components/rich_text.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47topContent_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/components/topContent.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47index_46jsxMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/index.jsx?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47jypx_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/jypx.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rccx_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/rccx.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rcdw_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/rcdw.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczl_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/rczl.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczp_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/rczp.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xq_47_91id_93_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/xq/[id].vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xxgk_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/rlzy/xxgk.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47sousuo_47index_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/sousuo/index.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47recommend_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xwzx/components/recommend.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47xwzxPage_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xwzx/components/xwzxPage.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47gjzc_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xwzx/gjzc.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47hyxw_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xwzx/hyxw.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47index_46jsxMeta } from "E:/develop/集团官网/zzrb-gw/pages/xwzx/index.jsx?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47lqxw_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xwzx/lqxw.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47xwxq_47_91id_93_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xwzx/xwxq/[id].vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47components_47xxgkPage_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xxgk/components/xxgkPage.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47gsgg_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xxgk/gsgg.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47index_46jsxMeta } from "E:/develop/集团官网/zzrb-gw/pages/xxgk/index.jsx?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47tzgg_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xxgk/tzgg.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47xq_47_91id_93_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/xxgk/xq/[id].vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47csfz_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/ywtx/csfz.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47gcjs_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/ywtx/gcjs.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47index_46jsxMeta } from "E:/develop/集团官网/zzrb-gw/pages/ywtx/index.jsx?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47kjwh_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/ywtx/kjwh.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq_47_91id_93_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/ywtx/xq/[id].vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq2_47_91id_93_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/ywtx/xq2/[id].vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47zbyz_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/ywtx/zbyz.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47index_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/zhuanti/[name]/index.vue?macro=true";
import { default as E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47xq_47_91specialTopicDetailId_93_46vueMeta } from "E:/develop/集团官网/zzrb-gw/pages/zhuanti/[name]/xq/[specialTopicDetailId].vue?macro=true";
export default [
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47components_47publicPage_46vueMeta?.name ?? "dqjs-components-publicPage",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47components_47publicPage_46vueMeta?.path ?? "/dqjs/components/publicPage",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/components/publicPage.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47components_47publicPage_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47components_47publicPage_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47components_47publicPage_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/components/publicPage.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djdt_46vueMeta?.name ?? "dqjs-djdt",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djdt_46vueMeta?.path ?? "/dqjs/djdt",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/djdt.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djdt_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djdt_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djdt_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/djdt.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djll_46vueMeta?.name ?? "dqjs-djll",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djll_46vueMeta?.path ?? "/dqjs/djll",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/djll.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djll_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djll_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47djll_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/djll.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47dyfc_46vueMeta?.name ?? "dqjs-dyfc",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47dyfc_46vueMeta?.path ?? "/dqjs/dyfc",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/dyfc.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47dyfc_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47dyfc_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47dyfc_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/dyfc.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47index_46jsxMeta?.name ?? "dqjs",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47index_46jsxMeta?.path ?? "/dqjs",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/index.jsx",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47index_46jsxMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47index_46jsxMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47index_46jsxMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/index.jsx").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47ljjs_46vueMeta?.name ?? "dqjs-ljjs",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47ljjs_46vueMeta?.path ?? "/dqjs/ljjs",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/ljjs.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47ljjs_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47ljjs_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47ljjs_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/ljjs.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47qnzs_46vueMeta?.name ?? "dqjs-qnzs",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47qnzs_46vueMeta?.path ?? "/dqjs/qnzs",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/qnzs.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47qnzs_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47qnzs_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47qnzs_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/qnzs.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xq_47_91partyBuildingId_93_46vueMeta?.name ?? "dqjs-xq-partyBuildingId",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xq_47_91partyBuildingId_93_46vueMeta?.path ?? "/dqjs/xq/:partyBuildingId",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/xq/[partyBuildingId].vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xq_47_91partyBuildingId_93_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xq_47_91partyBuildingId_93_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xq_47_91partyBuildingId_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/xq/[partyBuildingId].vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xxgcddesdjs_46vueMeta?.name ?? "dqjs-xxgcddesdjs",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xxgcddesdjs_46vueMeta?.path ?? "/dqjs/xxgcddesdjs",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/xxgcddesdjs.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xxgcddesdjs_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xxgcddesdjs_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47xxgcddesdjs_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/xxgcddesdjs.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47zgzj_46vueMeta?.name ?? "dqjs-zgzj",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47zgzj_46vueMeta?.path ?? "/dqjs/zgzj",
    file: "E:/develop/集团官网/zzrb-gw/pages/dqjs/zgzj.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47zgzj_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47zgzj_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47dqjs_47zgzj_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/dqjs/zgzj.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47components_47publicPage_46vueMeta?.name ?? "gywm-components-publicPage",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47components_47publicPage_46vueMeta?.path ?? "/gywm/components/publicPage",
    file: "E:/develop/集团官网/zzrb-gw/pages/gywm/components/publicPage.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47components_47publicPage_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47components_47publicPage_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47components_47publicPage_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/gywm/components/publicPage.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_46jsxMeta?.name ?? "gywm",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_46jsxMeta?.path ?? "/gywm",
    file: "E:/develop/集团官网/zzrb-gw/pages/gywm/index.jsx",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_46jsxMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_46jsxMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_46jsxMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/gywm/index.jsx").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_copy_46vueMeta?.name ?? "gywm-index_copy",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_copy_46vueMeta?.path ?? "/gywm/index_copy",
    file: "E:/develop/集团官网/zzrb-gw/pages/gywm/index_copy.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_copy_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_copy_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47index_copy_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/gywm/index_copy.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47jtjj_46vueMeta?.name ?? "gywm-jtjj",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47jtjj_46vueMeta?.path ?? "/gywm/jtjj",
    file: "E:/develop/集团官网/zzrb-gw/pages/gywm/jtjj.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47jtjj_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47jtjj_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47jtjj_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/gywm/jtjj.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47_91id_93_46vueMeta?.name ?? "gywm-lqqx-id",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47_91id_93_46vueMeta?.path ?? "/gywm/lqqx/:id",
    file: "E:/develop/集团官网/zzrb-gw/pages/gywm/lqqx/[id].vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47_91id_93_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47_91id_93_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/gywm/lqqx/[id].vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47index_46vueMeta?.name ?? "gywm-lqqx",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47index_46vueMeta?.path ?? "/gywm/lqqx",
    file: "E:/develop/集团官网/zzrb-gw/pages/gywm/lqqx/index.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47index_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47index_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47lqqx_47index_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/gywm/lqqx/index.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47qyry_46vueMeta?.name ?? "gywm-qyry",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47qyry_46vueMeta?.path ?? "/gywm/qyry",
    file: "E:/develop/集团官网/zzrb-gw/pages/gywm/qyry.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47qyry_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47qyry_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47qyry_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/gywm/qyry.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47zzjg_46vueMeta?.name ?? "gywm-zzjg",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47zzjg_46vueMeta?.path ?? "/gywm/zzjg",
    file: "E:/develop/集团官网/zzrb-gw/pages/gywm/zzjg.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47zzjg_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47zzjg_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47gywm_47zzjg_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/gywm/zzjg.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47index_47index_46vueMeta?.name ?? "index",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47index_47index_46vueMeta?.path ?? "/",
    file: "E:/develop/集团官网/zzrb-gw/pages/index/index.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47index_47index_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47index_47index_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47index_47index_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47qywh_47index_46vueMeta?.name ?? "qywh",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47qywh_47index_46vueMeta?.path ?? "/qywh",
    file: "E:/develop/集团官网/zzrb-gw/pages/qywh/index.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47qywh_47index_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47qywh_47index_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47qywh_47index_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/qywh/index.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47list_page_46vueMeta?.name ?? "rlzy-components-list_page",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47list_page_46vueMeta?.path ?? "/rlzy/components/list_page",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/components/list_page.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47list_page_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47list_page_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47list_page_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/components/list_page.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47rich_text_46vueMeta?.name ?? "rlzy-components-rich_text",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47rich_text_46vueMeta?.path ?? "/rlzy/components/rich_text",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/components/rich_text.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47rich_text_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47rich_text_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47rich_text_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/components/rich_text.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47topContent_46vueMeta?.name ?? "rlzy-components-topContent",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47topContent_46vueMeta?.path ?? "/rlzy/components/topContent",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/components/topContent.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47topContent_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47topContent_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47components_47topContent_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/components/topContent.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47index_46jsxMeta?.name ?? "rlzy",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47index_46jsxMeta?.path ?? "/rlzy",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/index.jsx",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47index_46jsxMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47index_46jsxMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47index_46jsxMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/index.jsx").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47jypx_46vueMeta?.name ?? "rlzy-jypx",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47jypx_46vueMeta?.path ?? "/rlzy/jypx",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/jypx.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47jypx_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47jypx_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47jypx_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/jypx.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rccx_46vueMeta?.name ?? "rlzy-rccx",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rccx_46vueMeta?.path ?? "/rlzy/rccx",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/rccx.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rccx_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rccx_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rccx_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/rccx.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rcdw_46vueMeta?.name ?? "rlzy-rcdw",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rcdw_46vueMeta?.path ?? "/rlzy/rcdw",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/rcdw.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rcdw_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rcdw_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rcdw_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/rcdw.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczl_46vueMeta?.name ?? "rlzy-rczl",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczl_46vueMeta?.path ?? "/rlzy/rczl",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/rczl.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczl_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczl_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczl_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/rczl.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczp_46vueMeta?.name ?? "rlzy-rczp",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczp_46vueMeta?.path ?? "/rlzy/rczp",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/rczp.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczp_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczp_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47rczp_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/rczp.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xq_47_91id_93_46vueMeta?.name ?? "rlzy-xq-id",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xq_47_91id_93_46vueMeta?.path ?? "/rlzy/xq/:id",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/xq/[id].vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xq_47_91id_93_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xq_47_91id_93_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xq_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/xq/[id].vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xxgk_46vueMeta?.name ?? "rlzy-xxgk",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xxgk_46vueMeta?.path ?? "/rlzy/xxgk",
    file: "E:/develop/集团官网/zzrb-gw/pages/rlzy/xxgk.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xxgk_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xxgk_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47rlzy_47xxgk_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/rlzy/xxgk.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47sousuo_47index_46vueMeta?.name ?? "sousuo",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47sousuo_47index_46vueMeta?.path ?? "/sousuo",
    file: "E:/develop/集团官网/zzrb-gw/pages/sousuo/index.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47sousuo_47index_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47sousuo_47index_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47sousuo_47index_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/sousuo/index.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47recommend_46vueMeta?.name ?? "xwzx-components-recommend",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47recommend_46vueMeta?.path ?? "/xwzx/components/recommend",
    file: "E:/develop/集团官网/zzrb-gw/pages/xwzx/components/recommend.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47recommend_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47recommend_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47recommend_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xwzx/components/recommend.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47xwzxPage_46vueMeta?.name ?? "xwzx-components-xwzxPage",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47xwzxPage_46vueMeta?.path ?? "/xwzx/components/xwzxPage",
    file: "E:/develop/集团官网/zzrb-gw/pages/xwzx/components/xwzxPage.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47xwzxPage_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47xwzxPage_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47components_47xwzxPage_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xwzx/components/xwzxPage.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47gjzc_46vueMeta?.name ?? "xwzx-gjzc",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47gjzc_46vueMeta?.path ?? "/xwzx/gjzc",
    file: "E:/develop/集团官网/zzrb-gw/pages/xwzx/gjzc.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47gjzc_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47gjzc_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47gjzc_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xwzx/gjzc.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47hyxw_46vueMeta?.name ?? "xwzx-hyxw",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47hyxw_46vueMeta?.path ?? "/xwzx/hyxw",
    file: "E:/develop/集团官网/zzrb-gw/pages/xwzx/hyxw.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47hyxw_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47hyxw_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47hyxw_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xwzx/hyxw.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47index_46jsxMeta?.name ?? "xwzx",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47index_46jsxMeta?.path ?? "/xwzx",
    file: "E:/develop/集团官网/zzrb-gw/pages/xwzx/index.jsx",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47index_46jsxMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47index_46jsxMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47index_46jsxMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xwzx/index.jsx").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47lqxw_46vueMeta?.name ?? "xwzx-lqxw",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47lqxw_46vueMeta?.path ?? "/xwzx/lqxw",
    file: "E:/develop/集团官网/zzrb-gw/pages/xwzx/lqxw.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47lqxw_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47lqxw_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47lqxw_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xwzx/lqxw.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47xwxq_47_91id_93_46vueMeta?.name ?? "xwzx-xwxq-id",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47xwxq_47_91id_93_46vueMeta?.path ?? "/xwzx/xwxq/:id",
    file: "E:/develop/集团官网/zzrb-gw/pages/xwzx/xwxq/[id].vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47xwxq_47_91id_93_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47xwxq_47_91id_93_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xwzx_47xwxq_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xwzx/xwxq/[id].vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47components_47xxgkPage_46vueMeta?.name ?? "xxgk-components-xxgkPage",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47components_47xxgkPage_46vueMeta?.path ?? "/xxgk/components/xxgkPage",
    file: "E:/develop/集团官网/zzrb-gw/pages/xxgk/components/xxgkPage.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47components_47xxgkPage_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47components_47xxgkPage_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47components_47xxgkPage_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xxgk/components/xxgkPage.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47gsgg_46vueMeta?.name ?? "xxgk-gsgg",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47gsgg_46vueMeta?.path ?? "/xxgk/gsgg",
    file: "E:/develop/集团官网/zzrb-gw/pages/xxgk/gsgg.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47gsgg_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47gsgg_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47gsgg_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xxgk/gsgg.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47index_46jsxMeta?.name ?? "xxgk",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47index_46jsxMeta?.path ?? "/xxgk",
    file: "E:/develop/集团官网/zzrb-gw/pages/xxgk/index.jsx",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47index_46jsxMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47index_46jsxMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47index_46jsxMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xxgk/index.jsx").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47tzgg_46vueMeta?.name ?? "xxgk-tzgg",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47tzgg_46vueMeta?.path ?? "/xxgk/tzgg",
    file: "E:/develop/集团官网/zzrb-gw/pages/xxgk/tzgg.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47tzgg_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47tzgg_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47tzgg_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xxgk/tzgg.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47xq_47_91id_93_46vueMeta?.name ?? "xxgk-xq-id",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47xq_47_91id_93_46vueMeta?.path ?? "/xxgk/xq/:id",
    file: "E:/develop/集团官网/zzrb-gw/pages/xxgk/xq/[id].vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47xq_47_91id_93_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47xq_47_91id_93_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47xxgk_47xq_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/xxgk/xq/[id].vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47csfz_46vueMeta?.name ?? "ywtx-csfz",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47csfz_46vueMeta?.path ?? "/ywtx/csfz",
    file: "E:/develop/集团官网/zzrb-gw/pages/ywtx/csfz.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47csfz_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47csfz_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47csfz_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/ywtx/csfz.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47gcjs_46vueMeta?.name ?? "ywtx-gcjs",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47gcjs_46vueMeta?.path ?? "/ywtx/gcjs",
    file: "E:/develop/集团官网/zzrb-gw/pages/ywtx/gcjs.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47gcjs_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47gcjs_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47gcjs_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/ywtx/gcjs.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47index_46jsxMeta?.name ?? "ywtx",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47index_46jsxMeta?.path ?? "/ywtx",
    file: "E:/develop/集团官网/zzrb-gw/pages/ywtx/index.jsx",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47index_46jsxMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47index_46jsxMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47index_46jsxMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/ywtx/index.jsx").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47kjwh_46vueMeta?.name ?? "ywtx-kjwh",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47kjwh_46vueMeta?.path ?? "/ywtx/kjwh",
    file: "E:/develop/集团官网/zzrb-gw/pages/ywtx/kjwh.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47kjwh_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47kjwh_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47kjwh_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/ywtx/kjwh.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq_47_91id_93_46vueMeta?.name ?? "ywtx-xq-id",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq_47_91id_93_46vueMeta?.path ?? "/ywtx/xq/:id",
    file: "E:/develop/集团官网/zzrb-gw/pages/ywtx/xq/[id].vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq_47_91id_93_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq_47_91id_93_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/ywtx/xq/[id].vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq2_47_91id_93_46vueMeta?.name ?? "ywtx-xq2-id",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq2_47_91id_93_46vueMeta?.path ?? "/ywtx/xq2/:id",
    file: "E:/develop/集团官网/zzrb-gw/pages/ywtx/xq2/[id].vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq2_47_91id_93_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq2_47_91id_93_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47xq2_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/ywtx/xq2/[id].vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47zbyz_46vueMeta?.name ?? "ywtx-zbyz",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47zbyz_46vueMeta?.path ?? "/ywtx/zbyz",
    file: "E:/develop/集团官网/zzrb-gw/pages/ywtx/zbyz.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47zbyz_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47zbyz_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47ywtx_47zbyz_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/ywtx/zbyz.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47index_46vueMeta?.name ?? "zhuanti-name",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47index_46vueMeta?.path ?? "/zhuanti/:name",
    file: "E:/develop/集团官网/zzrb-gw/pages/zhuanti/[name]/index.vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47index_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47index_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/zhuanti/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47xq_47_91specialTopicDetailId_93_46vueMeta?.name ?? "zhuanti-name-xq-specialTopicDetailId",
    path: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47xq_47_91specialTopicDetailId_93_46vueMeta?.path ?? "/zhuanti/:name/xq/:specialTopicDetailId",
    file: "E:/develop/集团官网/zzrb-gw/pages/zhuanti/[name]/xq/[specialTopicDetailId].vue",
    children: [],
    meta: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47xq_47_91specialTopicDetailId_93_46vueMeta,
    alias: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47xq_47_91specialTopicDetailId_93_46vueMeta?.alias || [],
    redirect: E_58_47develop_47_38598_22242_23448_32593_47zzrb_45gw_47pages_47zhuanti_47_91name_93_47xq_47_91specialTopicDetailId_93_46vueMeta?.redirect || undefined,
    component: () => import("E:/develop/集团官网/zzrb-gw/pages/zhuanti/[name]/xq/[specialTopicDetailId].vue").then(m => m.default || m)
  }
]